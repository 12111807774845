import { createTheme } from '@mui/material/styles';

const Main = "#0069b3";
const Light = "#D2D5DB";
const Grey = "#e6e6e6";
const Dark = '#3F4756';
const Darker = '#004488';
const navbarStyles = {
  drawer: {
    width: 240,
    flexShrink: 0,
  }
}

export const dashboardTheme = createTheme({
  palette: {
    primary: {
      light: Light,
      main: Main,
      dark: Dark,
      darker: Darker,
    },
  },
  overrides: {
    MuiDrawer: navbarStyles.drawer,

  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: Grey,
          fontWeight: "800",
          fontSize: "0.875rem"
        },
      },
    },
    MuiTextField: { // Add styles for MuiTextField
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Set the background color to white
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
    MuiTabs: {},
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: "600",
          },
        },
      },
    },
  },
});
