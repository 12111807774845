import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { Navigate } from "react-router-dom";
import { routeConfig } from './routeConfig';
import { Loading } from '../Utils';
const RoutesComponent = () => {
  const { token } = useAuth();
  return (
    <Router>
      <Suspense fallback={<Loading centered />}>
        <Routes>
          {routeConfig.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.protected && !token ? <Navigate to="/login" /> : route.element}
            />
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesComponent;
