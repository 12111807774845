import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import './styles.css';
import { ThemeProvider } from '@mui/material/styles';
import { dashboardTheme } from './dashboardTheme';
import CssBaseline from '@mui/material/CssBaseline';

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={dashboardTheme}>
      <CssBaseline />
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </ThemeProvider>
)