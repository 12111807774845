import React, { useEffect, useState } from 'react';
import AuthProvider from "./provider/authProvider";
import { TitleProvider } from './provider/TitleContext';
import { ErrorHandler } from './provider/ErrorHandler';
import { MessageProvider } from './provider/MessageContext';
import { setApiErrorCallback } from './services/interceptors'; // Import the setApiErrorCallback function
import Router from "./routes/";

function App() {
  const [errors, setErrors] = useState([]); // Initialize error state as an array

  useEffect(() => {
    const handleError = (statusCode) => {
      setErrors(prevErrors => [...prevErrors, statusCode]); // Add the new error to the array
    };

    // Set the error handling callback within useEffect
    setApiErrorCallback(handleError);

    // Optional: Cleanup function if needed when component unmounts
    return () => {
      // If you have a way to remove the error callback, do it here
      // This depends on your implementation of setApiErrorCallback
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <AuthProvider>
      <ErrorHandler errors={errors} setErrors={setErrors} />
      <TitleProvider>
        <MessageProvider>
          <Router />
        </MessageProvider>
      </TitleProvider>
    </AuthProvider>
  );
}

export default App;
