import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ScrollToTop from '../Utils/ScrollToTop';
import { useMainRef } from '../layouts/PrivateLayout';

interface MessageProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
  hideDuration?: number | false;
  open: boolean;  // Aggiunta della prop open
  onClose: () => void;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarMessage({ severity, message, onClose, open, hideDuration = 8000 }: MessageProps) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const mainRef = useMainRef();

  return (
    <>
      <ScrollToTop mainRef={mainRef} />
      <Snackbar 
        open={open} 
        autoHideDuration={hideDuration === false ? undefined : hideDuration}
        onClose={handleClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
