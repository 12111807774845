import { useAuth } from "../provider/authProvider"; // Aggiusta il percorso in base alla tua struttura
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

const LogoutButton = ({ variant = 'contained' }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login"); // Reindirizza all'utente alla pagina di login dopo il logout
  };

  return (
    <Button variant={variant} onClick={handleLogout}>Logout</Button>
  );
};

export default LogoutButton;