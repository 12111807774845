import React, { lazy } from 'react';
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import {ProtectedRoute} from "./ProtectedRoute";

const Login = lazy(() => import("../pages/Login"));
const RichiestaResetpassword = lazy(() => import("../pages/RichiestaResetpassword"));
const Resetpassword = lazy(() => import("../pages/ResetPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Annuncio = lazy(() => import("../pages/Annuncio"));
const ElencoAnnunci = lazy(() => import("../pages/ElencoAnnunci"));
const ElencoArticolo = lazy(() => import("../pages/ElencoArticoli"));
const Articolo = lazy(() => import("../pages/Articolo"));

export const routeConfig = [
    {
        path: "/login",
        element: <PublicLayout><Login /></PublicLayout>,
        protected: false
    },
    {
        path: "/",
        element: <ProtectedRoute />,
        protected: true
    },
    {
        path: "/dashboard",
        element: <PrivateLayout><Dashboard /></PrivateLayout>,
        protected: true
    },
    {
        path: "/annuncio/:id",
        element: <PrivateLayout><Annuncio /></PrivateLayout>,
        protected: true
    },
    {
        path: "/annuncio/",
        element: <PrivateLayout><Annuncio /></PrivateLayout>,
        protected: true
    },
    {
        path: "/elenco/",
        element: <PrivateLayout><ElencoAnnunci /></PrivateLayout>,
        protected: true
    },    
    {
        path: "/elenco-articoli/",
        element: <PrivateLayout><ElencoArticolo /></PrivateLayout>,
        protected: true
    },
    {
        path: "/articolo/:id",
        element: <PrivateLayout><Articolo /></PrivateLayout>,
        protected: true
    },
    {
        path: "/articolo/",
        element: <PrivateLayout><Articolo /></PrivateLayout>,
        protected: true
    },
    {
        path: "/richiesta-reset-password/",
        element: <PublicLayout><RichiestaResetpassword /></PublicLayout>,
        protected: false
    },
    {
        path: "/reset-password/",
        element: <PublicLayout><Resetpassword /></PublicLayout>,
        protected: false
    }
];