import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const location = useLocation();

  // Check if the user is authenticated
  if (token) {
    // If authenticated and the current path is "/", redirect to the dashboard
    if (location.pathname === "/") {
      return <Navigate to="/dashboard" />;
    }

    // If authenticated and the current path is not "/", render the child routes
   // return <Outlet />;
  }

  // If not authenticated, redirect to the login page
  return <Navigate to="/login" />;
};
