import { useEffect } from 'react';

const ScrollToTop = ({ mainRef }) => {

  useEffect(() => {

    const handleScroll = () => {
      try {
        if (mainRef && mainRef.current) {
          mainRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      } catch (err) {
        console.error('Error scrolling to top', err);
      }
    }

    handleScroll()

  }, [mainRef]);
  return null;

}

export default ScrollToTop;