import { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import Services from '../services/Services';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  
  const [token, setToken] = useState(localStorage.getItem("token"));

  const logout = useCallback(() => {
    Services.logout(localStorage.getItem("refreshToken"))
      .then((response) => {
        setToken(null);
        try {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
        } catch (err) { }
      })
      .catch((error) => {
        console.log(error)
      });
  }, []);
 

  useEffect(() => {
    if (token) {
      try {
        localStorage.setItem('token', token);
      } catch (err) {
        console.error('Error saving token', err);
      }

    } else {
      // Esegui operazioni aggiuntive quando l'utente effettua il logout.
      localStorage.removeItem("token");
    }
  }, [token]);

  const contextValue = useMemo(() => ({
    token,
    setToken,
    logout,
  }), [token, logout]);
  
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

export default AuthProvider;


