import api from './interceptors';
 
const Services = {
  listaAnnunci: async (page = 0) => {
    return api.get(`annunci/?page=${page}`);
  },  
  ricercaAnnunci: async (data) => {
    return api.post(`annunci/ricerca`, data);
  },
  annuncioSalva: async (idAnn, data) => {
    return api.post('annuncio'+(idAnn ? '/'+idAnn : ""), data);
  },
  listaAllegati: async (idLotto, tipologia) => {
    return api.get(`allegati/ricerca?lotto_id=${idLotto}&tipologia=${tipologia}`);
  },
  updateTipologiaAllegato: async (tipologia, idAll) => {
    return api.get(`/allegati/${idAll}/update-tipologia?tipologia=${tipologia}`);
  },
  cancellaAllegato: async (idAll) => {
    return api.get(`allegati/${idAll}/delete`);
  },
  cancellaAnnuncio: async (idAnn) => {
    return api.get(`annuncio-elimina/${idAnn}`);
  },
  annuncio: async (idAnn = '') => {
    return api.get(`annuncio/${idAnn}`);
  },
  categorie: async () => {
    return api.get('categorie');
  },
  localitaIt: async () => {
    return api.get('localita-it');
  },
  localitaItCercaComune: async (comune = '') => {
    return api.get(`localita-it-cerca-comune/${comune}`);
  },
  tribunali: async () => {
    return api.get('tribunali');
  },
  cortiAppello: async () => {
    return api.get('corti-appello');
  }, 
  fornitori: async () => {
    return api.get('fornitori');
  },
  tipoBene: async () => {
    return api.get('tipo-bene');
  },  
  TipoProcedura: async () => {
    return api.get('tipo-procedura');
  },
  user: async () => {
    return api.get('user');
  },
  refreshToken: async () => {
    return api.post('refresh');
  },
  logout: async (refreshToken) => {
    return api.post('logout', {'refreshToken' : refreshToken});
  },
  listaArticoli: async () => {
    return api.get('articoli');
  },
  articolo: async (idArt = '') => {
    return api.get(`articoli/${idArt}`);
  },
  articoloSalva: async (idArt, data) => {
    return api.post('articolo'+(idArt ? '/'+idArt : ""), data);
  },
  cancellaArticolo: async (idArt) => {
    return api.get(`articolo/${idArt}/delete`);
  },
  listaCategorie: async () => {
    return api.get('categorie');
  },
  richiestaResetPassword: async (data) => {
    return api.post(`/password/richiesta-reset`, data);
  },
  resetPassword: async (data) => {
    return api.post(`/password/reset`, data);
  },
  ricercaArticoli: async (data) => {
    return api.post('articoli/ricerca', data);
  },
  ricercaCopertinaDaArticolo: async (idArticolo) => {
    return api.get(`/uploadCopertinaArticolo/ricerca?art_id=${idArticolo}`);
  },
  cancellaCopertinaArticolo: async (idAll) => {
    return api.get(`uploadCopertinaArticolo/${idAll}/delete`);
  },
  uploadImgCKE: async (formData) => {
    return api.post('uploadImgCKE', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default Services;