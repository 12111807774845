import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Loading({ centered, customStyle, showMessage, message }) {
  const containerStyle = {
    display: 'flex',
    justifyContent: centered ? 'center' : 'flex-start',
    alignItems: centered ? 'center' : 'flex-start',
    height: '100vh',
    ...customStyle,
  };
  const typographyStyle = {
    marginLeft: '8px', 
  };

  return (
    <div style={containerStyle}>
      <CircularProgress  />
       {showMessage && message && <Typography  style={typographyStyle} variant="overline" display="block" gutterBottom> {message}</Typography>}
    </div>
  );
}

export default Loading;
