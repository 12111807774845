import * as React from 'react';
import {useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainNavbarItems } from './consts/navbarItems';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
 
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
 
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from "react-router-dom";
import Logo from '../logo';


const Drawer = styled(MuiDrawer)(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: theme.overrides.MuiDrawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
    '& .logo': {
      width: '100%',  
      height: 'auto', 
      paddingLeft: '50px',
    },
  }),
);


function Navbar({ open, toggleOpen }) {
  const navigate = useNavigate();
  const [openSub, setOpenSub] = React.useState([]);
 
  const location = useLocation(); // Get the current location

  const handleClick = (index) => {
    const newOpenSub = [...openSub];
    newOpenSub[index] = !newOpenSub[index];
    setOpenSub(newOpenSub);
  };

  const [currentPage, setCurrentPage] = useState(location.pathname); // Initial page

  // Use useEffect to update the currentPage state when the location changes
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);


    // Funzione per verificare se un elemento del submenu è aperto
    const isSubmenuOpen = (submenuItems,parentRoute) => {
      for (const subItem of submenuItems) {
        if (currentPage.startsWith(parentRoute) || subItem.route === currentPage) {
          return true;
        }
      }
      return false;
    };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}>
          <Logo />
          <IconButton onClick={toggleOpen}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {mainNavbarItems.map((item, index) => (
            <React.Fragment key={item.id}>
              {item.submenu ? (
                <>
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {openSub[index] ||  isSubmenuOpen(item.submenu, item.route) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse in={openSub[index] || isSubmenuOpen(item.submenu, item.route)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.submenu.map((subItem) => (
                        <ListItem
                          button
                          key={subItem.id}
                          onClick={() => {
                            navigate(subItem.route);    
                            setCurrentPage(subItem.route);                
                          }}    
                         
                          sx={{pl: 4, backgroundColor: currentPage === subItem.route ? '#f5f5f5' : 'transparent' }}
                        >
                          <ListItemIcon>
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={subItem.label} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItem
                  button
                 onClick={() => {
                  navigate(item.route);
                  setCurrentPage(item.route);
                }}
                sx={{ backgroundColor: currentPage === item.route ? '#f5f5f5' : 'transparent' }}

                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </>
  );
}
export default Navbar;