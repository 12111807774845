import React, { createContext, useState, useContext } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const setMessageWithSeverity = (msg, sev) => {
    setMessage(msg);
    setSeverity(sev);
  };

  const clearMessage = () => {
    setMessage('');
    setSeverity('info');
  };

  return (
    <MessageContext.Provider value={{ message, severity, setMessageWithSeverity, clearMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
