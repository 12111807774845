import { useEffect, useState } from 'react';
import { useAuth } from './authProvider';
import SnackbarMessage from '../helpers/SnackbarMessage';

export const ErrorHandler = ({ errors, setErrors }) => {
  const { logout } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (errors && errors.length > 0) {
      const currentError = errors[0]; 
      handleErrorResponse(currentError);
    }
  }, [errors]);

  const handleErrorResponse = (statusCode) => {
    let message = '';
    switch (statusCode) {
      case 400:
        message = 'Errore 400 - Bad Request';
        break;
      case 401:
        message = 'Errore 401 - Unauthorized';
        logout();
        break;
      case 404:
        message = 'Errore 404 - Not Found';
        break;
      case 500:
        message = 'Errore 500 - Internal Server Error';
        break;
      default:
        message = 'Errore HTTP sconosciuto';
        break;
    }
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    }

    // Pulisci l'errore attualmente gestito
    setErrors(prevErrors => prevErrors.filter(err => err !== statusCode));
  };

  return (
    <SnackbarMessage
      open={openSnackbar}
      message={snackbarMessage}
      onClose={() => setOpenSnackbar(false)}
    />
  );
};
