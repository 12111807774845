import { useEffect } from 'react';
import {useTitle} from '../provider/TitleContext';

 function Title(title) {
   const { setTitle } = useTitle();
   const { setPageTitle } = useTitle();

   useEffect(() => {
    setTitle(title);
    setPageTitle(title)
  }, [title]);
}

export default Title;