import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import FeedIcon from '@mui/icons-material/Feed';
import AddBoxIcon from '@mui/icons-material/AddBox'; 
import ListIcon from '@mui/icons-material/List';
export const mainNavbarItems = [
    {
        id: 0,
        icon: <DashboardIcon />,
        label: 'Dashboard',
        route: '/dashboard',
    },
    {
        id: 1,
        icon: <FeedIcon />,
        label: 'Annunci',
        route: '/annuncio',
        submenu: [
            {
                id: 2,
                icon: <ListIcon />,
                label: 'Elenco annunci',
                route: '/elenco',
            }, {
                id: 3,
                icon: <AddBoxIcon />,
                label: 'Aggiungi annuncio',
                route: '/annuncio',
            },
            // Altri sottomenu degli annunci possono essere aggiunti qui
        ],
    },
    {
        id: 4,
        icon: <ArticleIcon />,
        label: 'Articoli',
        route: '/articolo',
        submenu: [
            {
                id: 5,
                icon: <ListIcon />,
                label: 'Elenco articoli',
                route: '/elenco-articoli',
            }, {
                id: 6,
                icon: <AddBoxIcon />,
                label: 'Aggiungi articolo',
                route: '/articolo',
            },
            // Altri sottomenu degli annunci possono essere aggiunti qui
        ],
    },
]