export const LOGO = require('./assets/img/logo.jpg');
export const APPNAME = 'Legalmente';

// Creiamo una costante per l'ambiente
const ENVIRONMENT = process.env.NODE_ENV;

// Esportiamo una costante che conterrà url di default
let apiUrl = '';
let baseApiUrl = '';
let pvpApiUrl = '';
let websiteUrl = '';
let recaptcha_SITE_URL = '6LcXsmspAAAAAOF--MW6-z38BTz2zjArn4JicDXD';

if (ENVIRONMENT === 'development') {
    apiUrl = 'http://webtest.api.legalmente.net/api/v1/';
    baseApiUrl = '//webtest.api.legalmente.net';
    websiteUrl = 'https://wwww.legalmente.net';
    pvpApiUrl = 'https://pvp.giustizia.it';
} else if (ENVIRONMENT === 'pre-production') {
    apiUrl = 'https://pre-api.legalmente.net/api/v1/';
    baseApiUrl = '//pre-api.legalmente.net';
    websiteUrl = 'https://pre.legalmente.net';
    pvpApiUrl = 'https://pvp.giustizia.it';
} else if (ENVIRONMENT === 'production') {
    apiUrl = 'https://pre-api.legalmente.net/api/v1/';
    baseApiUrl = '//pre-api.legalmente.net';
    websiteUrl = 'https://pre.legalmente.net';
    pvpApiUrl = 'https://pvp.giustizia.it';
}

export const API_URL = apiUrl;
export const BASE_API_URL = baseApiUrl;
export const PVP_API_URL = pvpApiUrl;
export const RECAPTCHA_SITE_URL = recaptcha_SITE_URL;
export const WEBSITEURL = websiteUrl;
