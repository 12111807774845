import React, { useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import Header from '../components/Header/Header';
import Navbar from '../components/Navbar/Navbar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainRefContext = React.createContext();

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate(); // Hook per la navigazione
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(!isMobile);
  const mainRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    handleResize(); // Controlla le dimensioni iniziali della finestra
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleRedirectToLogin = () => navigate('/login');
    
    window.addEventListener('redirectToLogin', handleRedirectToLogin);

    return () => {
      window.removeEventListener('redirectToLogin', handleRedirectToLogin);
    };
  }, [navigate]); // Aggiunto useEffect per ascoltare l'evento di reindirizzamento

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <MainRefContext.Provider value={mainRef}>
      <Box sx={{ display: 'flex' }}>
        <Header open={open} toggleOpen={toggleOpen} />
        <Navbar open={open} toggleOpen={toggleOpen} />
        <Box component="main" ref={mainRef}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </MainRefContext.Provider>
  );
};

export default PrivateLayout;

function useMainRef() {
  return useContext(MainRefContext);
}

export { useMainRef };
