import { createContext, useContext, useState, useEffect } from 'react';
import { APPNAME } from '../constants';
const TitleContext = createContext();

export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState(document.title);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
     //document.title = title +' - '+ APPNAME;
    document.title = title ? title +' - '+ APPNAME : APPNAME
  }, [title]);

  return (
    <TitleContext.Provider 
      value={{
        title, 
        setTitle,
        pageTitle,
        setPageTitle
      }}
    >
      {children}
    </TitleContext.Provider>
  )
}

export const useTitle = () => {
  return useContext(TitleContext);
}