import { useState, useEffect } from 'react';
import Services from '../services/Services'; 
import SnackbarMessage from '../helpers/SnackbarMessage';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          setLoading(true);
          const response = await Services.user();
          setUser(response.data);
          setError(null); // Clear error on successful call
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProfile();
    }, []);
  
    if (loading) {
      return <>Loading...</>;
    }

    if (error) {
      setOpenSnackbar(true);
      return <SnackbarMessage
        severity='error'
        message={error.message || 'An error occurred'}
        hideDuration={false}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      />
    }
  
    if (!user) {
      return <>No user data available</>;
    }

    return (
      <>{user.nome}</>
    );
};
  
export default Profile;
