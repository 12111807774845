import React, { useRef, useContext } from 'react';
import Container from '@mui/material/Container';
const MainRefContext = React.createContext();

const PublicLayout = ({ children }) => {
  const mainRef = useRef(null);
  return ( 
    <MainRefContext.Provider value={mainRef}>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
    </MainRefContext.Provider>
  );
};
export default PublicLayout;

function useMainRef() {
  return useContext(MainRefContext);
}

export { useMainRef }; 