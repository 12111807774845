import React from 'react';
import {LOGO,APPNAME}  from '../constants'

const Logo = () => {
  return (
    <>
      <img src={LOGO} alt={APPNAME} className='logo' />
    </>
  );
};

export default Logo;